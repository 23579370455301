import React, { useEffect, useState } from 'react';
import './index.css';
import selfie from '../images/selfie.jpg';
import Bio from '../components/bio';
import Links from '../components/links';
import { Helmet } from "react-helmet"
import SEO from '../components/seo';

function Index() {
  return (
<div>
    <SEO />
    <body class="site">
      <div class="container">
      <div class="header-section">
        <div class="header-frame">
          <h1>Owen Collier-Ridge</h1>
          <h2>Software Engineer Founder: Owen Codes, LLC</h2>
        </div>
	<div class="img-frame">
	  <img src={selfie} alt="A selfie of me :-)" />
	</div>
      </div>
      <div class="links">
      	<Links />
      </div>
      <div class="bio">
      	<Bio />
      </div>
      </div>
    </body>
      </div>

  );
}

export default Index;
