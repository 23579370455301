import React from "react"
import { Helmet } from "react-helmet"
import selfie from '../images/selfie.jpg';
class SEO extends React.Component {

  render() {
    return (
      <div className="application">
    <Helmet title="Owen Collier-Ridge, Software Engineer in San Francisco" >
      <meta name="description" content="Owen Collier-Ridge is a software engineer specializing in Python and Cloud Architectures. Here are my links. For Consulting: owen@owen.dev" />
      <meta name="image" content={selfie} />
      <meta property="og:url" content="https://owen.dev" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Owen Collier-Ridge, Software Engineer in San Francisco" />
      <meta property="og:description" content="Owen Collier-Ridge is a software engineer specializing in Python and Cloud Architectures. Here are my links. For Consulting: owen@owen.dev" />
      <meta property="og:image" content={selfie} />
      <meta name="twitter:card" content={selfie} />
      <meta name="twitter:creator" content="AWSOwen" />
      <meta name="twitter:title" content="Owen Collier-Ridge Software Engineer in San Francisco"/>
      <meta name="twitter:description" content="Owen Collier-Ridge is a software engineer specializing in Python and Cloud Architectures. Here are my links. For Consulting: owen@owen.dev" />
      <meta name="twitter:image" content={selfie} />
    </Helmet>
      </div>
    )
  }
}

export default SEO;
