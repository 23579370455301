import React from "react"

export default function Links(){
	return       <body>
      <div class="links">
        <a href="http://blog.owen.dev">blog</a>
        <a href="mailto:owen@owen.dev">email</a>
      </div>
      <div class="links">
        <a href="https://twitter.com/AWSOwen">twitter</a>
        <a href="http://github.com/BoraxTheClean">github</a>
        <a href="https://www.linkedin.com/in/owen-collier-ridge/">linkedin</a>
      </div>
		</body>
}
