import React from "react"

class Bio extends React.Component {
	constructor(props) {
		super(props);
		this.newbio = <div class="bio"> <p class="bio">
			Currently residing in San Francisco, I'm a seasoned Senior Software Engineer at Curative. While I've worked across the stack, my sweet spot is in infrastructure. I'm passionate about tackling complex challenges and am now open to new opportunities where I can dive even deeper into the stack. Let's connect and create something impactful.

			</p>
			</div>;


	}
	render(){
		return (
			<>
				{ this.newbio }
			</>
		);
	}

}

export default Bio
